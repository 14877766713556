/* Base and global styles */
html,
body,
#root {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

#root hr {
    border: none;
    background-color: #0b0c0c;
    height: 5px;
    margin: 30px 0px 30px 0px;
}

#root .govuk-radios__item {
    margin-bottom: 20px;
}

#root .govuk-breadcrumbs {
    margin-top: 24px;
    margin-bottom: 0px;
}

.rpo-block {
    padding: 20px;
    background: var(--idsk-gray-grey-4, #f3f2f1);
    margin-bottom: 27px;
}

.rpo-warn {
    color: #d0190f !important;
    font-size: 24px;
    font-size: 1.5rem;
}

.rpo-mb-33 {
    margin-bottom: 33px;
}

.rpo-primary {
    color: #0b0c0c !important;
}

.rpo-secondary {
    color: #626a6e !important;
}

.rpo-flex {
    display: flex;
}

.rpo-flex-gap {
    gap: 16px;
}

.rpo-no-margin {
    margin: 0 0 0 0 !important;
}

.rpo-margin-t-none {
    margin-top: 0;
}

.rpo-flex-1 {
    flex: 1;
}

.govuk-summary-list.rpo-attachment-uploading-msg {
    display: flex;
    gap: 16px;
    align-items: center;
    color: #0b0c0c;
    font-family:
        Source Sans Pro,
        Arial,
        sans-serif;
    font-weight: 700;
    border-top: 1px solid #bfc1c3;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mc-expandable-list-item__date {
    color: var(--idsk-text-secondary, #626a6e);
}

.rpo-bold {
    font-weight: 700 !important;
}

.rpo-form-array {
    display: flex;
}

.rpo-form-array > .govuk-form-group {
    flex: 1;
}

.rpo-form-array > button {
    width: 40px;
    height: 40px;
    margin: 25px 0 0 0;
    background-color: transparent;
    border: 2px solid #0b0c0c;
    border-left: none;
    font-size: 1.2rem;
    cursor: pointer;
}

.rpo-grey {
    color: #505a5f !important;
}

.rpo-form-array > button:hover {
    box-shadow: inset 0 0 0 2px;
}

.rpo-form-array > button:focus {
    outline: 3px solid #ffdf0f;
    box-shadow: inset 0 0 0 2px;
}

#root .rpo-attachment-title {
    margin-bottom: 8px;
}

/* Form group */
#root .govuk-form-group.rpo-conditional-group {
    margin-left: 17px;
    padding-left: 33px;
}

#root .govuk-form-group.rpo-conditional-group:not(.govuk-form-group--error) {
    border-left: 5px solid #b1b4b6;
}

/* Footer */
.idsk-footer-extended-logo {
    max-height: 70px;
}

/* Header */
.idsk-header-web__main--login-action {
    line-height: 0;
}

.idsk-header-web__main--login-action > .idsk-header-web__main--login-action-text > * {
    margin: 0;
}

/* Initialization loader */
.rpo-initialization {
    z-index: 999999;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    opacity: 1;
}

.rpo-initialization-content {
    margin: 25%;
    align-self: center;
    text-align: center;
    width: 100%;
}

.rpo-initialization .rpo-initialization-content h1,
.rpo-initialization .rpo-initialization-content div {
    width: 100%;
    color: #003078;
}

.rpo-initialization__hide {
    opacity: 0;
    transition: opacity 1s;
}

.rpo-no-border {
    border: none;
}

/* Summary list */
#root .govuk-summary-list .govuk-summary-list__actions-list-item .govuk-link {
    font-size: 16px;
    font-size: 1rem;
}

/* Captcha */
.rpo-captcha {
    padding: 0;
    margin: 0;
    border: none;
}

.rpo-captcha__content {
    display: flex;
    gap: 16px;
    flex-direction: row;
    margin-bottom: 20px;
}

.rpo-captcha__controls {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
}

.rpo-captcha__controls > button {
    width: 47px;
    height: 47px;
    margin: 0;
    background-color: transparent;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
}

.rpo-captcha__controls > button:hover {
    box-shadow: inset 0 0 0 3px;
}

.rpo-captcha__controls > button:focus {
    outline: 3px solid #ffdf0f;
    box-shadow: inset 0 0 0 3px;
}

/* Captcha dialog */
.rpo-captcha-dialog {
    width: 100%;
    max-width: 400px;
}

.rpo-captcha-dialog__backdrop {
    padding-top: 75px;
    z-index: 999999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(11, 12, 12, 0.6);
}

.rpo-captcha-dialog__btns {
    display: flex;
    gap: 16px;
    width: 100%;
}

.rpo-captcha-dialog__btns > button {
    margin-bottom: 0;
    flex: 1;
}

/* Content loader */
.rpo-loader-content {
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rpo-loader-content__icon {
    font-size: 48px;
    font-size: 3rem;
    color: #626a6e;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.rpo-loader-content__icon.rpo-loader-content__icon__small {
    font-size: inherit;
    color: #626a6e;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.rpo-loader-content > h2 {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Organization detail */
.rpo-org-detail__action-btns {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rpo-org-detail__action-btns > button:not(:last-of-type) {
    margin-bottom: 0px;
}

.rpo-org-detail__error-list {
    list-style: none;
    padding: 0;
}

.rpo-two-column-list-item {
    flex-direction: column;
    gap: 14px;
    padding: 10px 0;
}

.rpo-two-column-list-item__subtitles {
    color: var(--idsk-text-primary, #0b0c0c);
    font-weight: 700;
    margin: 0;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
}

.rpo-two-column-list-item__data {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 10px;
}

.rpo-two-column-list-item__data__list {
    list-style: none;
    padding: 0;
}

.rpo-two-column-list-item__data__list > li {
    padding: 0 0 8px 0;
}

.rpo-two-column-list-item__data__list .mc-expandable-list-item__dates {
    align-self: stretch;
    column-gap: 20px;
    display: flex;
}

.rpo-two-column-list-item__data__list .mc-expandable-list-item__dates > .mc-expandable-list-item__date {
    flex: 1 1;
}

.mc-expandable-list-item.rpo-two-column-list-item__wrapper > .mc-expandable-list-item__content {
    padding: 0 40px 0 0;
}

.mc-expandable-list-item.rpo-two-column-list-item__wrapper > .mc-expandable-list-item__content > section {
    align-self: stretch;
}

.rpo-kuv-person__list > li {
    padding: 8px 0 8px 0;
}

/* Search results */
#root .rpo-search-results__page-size {
    margin-top: -7px;
    margin-bottom: 27px;
}

#root .govuk-select {
    width: 100%;
}

#root .rpo-search-results__page-size > .govuk-body {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
}

/* SuccessInfo */

button.govuk-link.mc-success-info--button {
    color: white !important;
    font-size: 19px !important;
    font-size: 1.1875rem !important;
}

button.govuk-link.mc-success-info--button:focus {
    color: #0065b3 !important;
}

/* Filter */

#root .idsk-table-filter__panel .idsk-table-filter__filter-inputs {
    padding-bottom: 0;
}

#root .idsk-table-filter__category.idsk-table-filter--expanded:last-of-type {
    margin-bottom: 5px;
}

#root .govuk-form-group {
    margin-bottom: 15px;
}

#root .govuk-checkbox-form-group {
    padding-top: 5px;
}

#root .rpo-checkbox-padding {
    padding-top: 5px;
}

#root .rpo-register-ovm {
    padding-bottom: 25px;
}

/* summary card on small */

#root .rpo-attachment .govuk-summary-card__content .govuk-summary-list__row:last-of-type {
    border-bottom: none;
}

@media (min-width: 48.0625em) {
    .idsk-header-web__main > .govuk-width-container > .govuk-grid-row > .govuk-grid-column-one-third-from-desktop {
        float: left;
        width: 50%;
    }
    .idsk-header-web__main > .govuk-width-container > .govuk-grid-row > .govuk-grid-column-two-thirds {
        float: left;
        width: 50%;
    }
}

@media (min-width: 40.0625em) {
    #root .govuk-form-group {
        margin-bottom: 20px;
    }

    #root .rpo-search-results__page-size {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }

    #root .rpo-search-results__page-size > div.govuk-form-group {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0;
    }

    #root .rpo-search-results__page-size > div.govuk-form-group > .govuk-label {
        margin: 0;
    }

    #root .rpo-search-results__page-size > div.govuk-form-group > .govuk-select {
        width: initial;
    }

    #root .rpo-search-results__page-size > .idsk-button.idsk-button--secondary {
        margin: -2px 0 0 0;
    }

    #root .rpo-two-column-list-item {
        flex-direction: row;
        gap: initial;
    }

    #root .rpo-form-array > button {
        margin: 30px 0 0 0;
    }

    #root .rpo-org-detail__action-btns {
        flex-direction: row;
        gap: 20px;
    }

    #root .rpo-org-detail__action-btns > button {
        margin-bottom: 20px;
    }

    #root button.govuk-link.mc-success-info--button {
        font-size: 24px !important;
        font-size: 1.5rem !important;
    }

    /* Filter */
    #root .govuk-form-group {
        margin-bottom: 20px;
    }

    #root .rpo-checkbox-padding {
        padding-top: 30px;
    }

    /* Summary list */
    #root .govuk-summary-list .govuk-summary-list__actions-list-item .govuk-link {
        font-size: 19px;
        font-size: 1.1875rem;
    }

    #root .govuk-heading-xl {
        margin-bottom: 48px;
    }

    #root .govuk-main-wrapper--auto-spacing:first-child,
    .govuk-main-wrapper--l {
        padding-top: 40px;
    }

    #root .govuk-body:not(.idsk-filter-menu__toggle),
    #root .govuk-body-m:not(.idsk-filter-menu__toggle) {
        margin-bottom: 25px;
    }

    #root .rpo-attachment .govuk-summary-list__key {
        width: 50%;
    }

    #root .rpo-attachment .govuk-summary-list__value {
        width: 30%;
    }

    /* hr */

    #root hr {
        margin: 40px 0px 40px 0px;
    }
}
